import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: { stateSet: false, sendResponse: undefined },
};

const formDataSlice = createSlice({
    name: "formData",
    initialState,
    reducers: {
        setFormData(state, action) {
            console.log("form data reducer received payload", action.payload);
            localStorage.setItem("formId", action.payload.formId)
            localStorage.setItem("responseId", action.payload.responseId)
            localStorage.setItem("username", action.payload.username)
        },

        resetFormData(state, action) {
            localStorage.setItem("formId", "")
            localStorage.setItem("responseId", "")
            localStorage.setItem("username", "")
        },
    },
});

export const { setFormData, resetFormData } = formDataSlice.actions;
export default formDataSlice.reducer;
