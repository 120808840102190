
import AppContent from "./AppContent";
import isAuthenticated from "./components/hoc/isAuthenticated";
import { Widget, PopupButton } from '@typeform/embed-react'

function App() {
  return (
    <>
      <AppContent />

      {/* <PopupButton id="GWUUeJZz" style={{ fontSize: 20 }} className="my-button">
        click to open form in popup
      </PopupButton> */}
    </>
  );
}

export default isAuthenticated(App);
// export default App;
