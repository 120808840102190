import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../assets/logo/logo-icon.svg';
import { NavLink } from 'react-router-dom';
import DropDownMenu from './DropDownMenu';
import { useDispatch } from "react-redux";
import { logOutUser } from "../reducers/login";
import { FaUserAlt } from "react-icons/fa";
import { LiaFileContractSolid } from "react-icons/lia";
import { IoMdHome } from "react-icons/io";
import { FaQuestionCircle } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoChatbubbles } from "react-icons/io5";
import userImage from "../assets/images/user-01.svg"
import { useEffect, useState } from 'react';
import { FaArrowLeft } from "react-icons/fa";

const Header = ({
    sidebarOpen,
    setSidebarOpen, homeScreen
}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [currentPagePath, setCurrentPagePath] = useState(undefined)

    function onLogout() {
        dispatch(logOutUser());
    }

    useEffect(() => {
        // console.log("location>>", location.pathname.split("/")[1])
        setCurrentPagePath(location.pathname.split("/")[1])
    }, [location])

    return (
        <header
            className={`sticky top-0 z-[30]  ${homeScreen ? "bg-none" : "bg-brand_bg"} lg:bg-white lg:mt-8 lg:mx-20 lg:rounded-lg`}
        >
            <div
                className="lg:h-full h-[10vh] flex flex-grow items-center justify-between py-4 px-4 shadow-2 md:px-6 2xl:px-11"
            >
                {/* mobile menu */}
                <div className="w-full flex justify-between items-center gap-2 sm:gap-4 lg:hidden">
                    {
                        homeScreen && <Link className="block flex-shrink-0 lg:hidden" to="/">
                            <span className={`text-lg ${homeScreen ? "text-white" : " text-brand_btn"}`}>Validtech.AI</span>
                        </Link>
                    }
                    {
                        !homeScreen &&
                        <div className='flex justify-start items-center uppercase'>
                            <FaArrowLeft className='mr-2 text-xl' onClick={() => navigate("/")} />
                            <span>
                                {currentPagePath}
                            </span>
                        </div>
                    }


                    {/* <!-- Hamburger Toggle BTN --> */}
                    <button
                        aria-controls="sidebar"
                        onClick={(e) => {
                            e.stopPropagation();
                            setSidebarOpen(!sidebarOpen);
                        }}
                        className="z-99999 block shadow-sm lg:hidden"
                    >
                        <GiHamburgerMenu className={`text-3xl ${homeScreen ? " text-white" : " text-black"}`} />
                    </button>
                </div>
                {/* temp div to justify nav center */}
                {/* web menu */}
                <div className="hidden w-full lg:flex justify-between items-center gap-3 2xsm:gap-7">
                    <Link className="block" to="/">
                        {/* <img src={Logo} alt="Logo" /> */}
                        <span className={`text-lg font-bold ${homeScreen ? " text-white lg:text-brand_btn" : " text-brand_btn"}`}>Validtech.AI</span>
                    </Link>
                    <ul className="flex items-center gap-2 2xsm:gap-4 font-bold">
                        <li>
                            <NavLink
                                to="/"
                                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4  text-black duration-300 ease-in-out hover:text-white hover:bg-graydark dark:hover:bg-meta-4 `}
                                onClick={() => setSidebarOpen(!sidebarOpen)}
                            >
                                <IoMdHome className='text-2xl' />
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/billing"
                                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4  text-black duration-300 ease-in-out hover:text-white hover:bg-graydark dark:hover:bg-meta-4`}
                                onClick={() => setSidebarOpen(!sidebarOpen)}
                            >
                                <LiaFileContractSolid className='text-2xl' />
                                Billing Details
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/faq"
                                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4  text-black duration-300 ease-in-ou  hover:text-white hover:bg-graydark dark:hover:bg-meta-4`}
                                onClick={() => setSidebarOpen(!sidebarOpen)}
                            >
                                <IoChatbubbles className='text-2xl' />
                                FAQ
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/policy"
                                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4  text-black duration-300 ease-in-out hover:text-white hover:bg-graydark dark:hover:bg-meta-4`}
                                onClick={() => setSidebarOpen(!sidebarOpen)}
                            >
                                <FaQuestionCircle className='text-xl' />
                                Privacy Policy
                            </NavLink>
                        </li>
                    </ul>

                    <DropDownMenu
                        buttonContent={
                            <div className='flex justify-start items-center'>
                                {/* <FaUserAlt className='text-2xl text-black hover:text-slate-400' /> */}
                                <img src={userImage} alt="user" width={44} height={44} />
                            </div>

                        }
                        dropDownContent={
                            <div
                                className={`absolute right-[-50px] mt-4 flex w-62.5 flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark block`}
                            >
                                <ul className="flex flex-col gap-5 border-b border-stroke px-6 py-7.5 dark:border-strokedark">
                                    <li>
                                        <Link
                                            to="/profile"
                                            className="flex items-center gap-3.5 text-sm  duration-300 ease-in-out hover:text-primary lg:text-base"
                                        >
                                            <svg
                                                className="fill-current"
                                                width="22"
                                                height="22"
                                                viewBox="0 0 22 22"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M11 9.62499C8.42188 9.62499 6.35938 7.59687 6.35938 5.12187C6.35938 2.64687 8.42188 0.618744 11 0.618744C13.5781 0.618744 15.6406 2.64687 15.6406 5.12187C15.6406 7.59687 13.5781 9.62499 11 9.62499ZM11 2.16562C9.28125 2.16562 7.90625 3.50624 7.90625 5.12187C7.90625 6.73749 9.28125 8.07812 11 8.07812C12.7188 8.07812 14.0938 6.73749 14.0938 5.12187C14.0938 3.50624 12.7188 2.16562 11 2.16562Z"
                                                    fill=""
                                                />
                                                <path
                                                    d="M17.7719 21.4156H4.2281C3.5406 21.4156 2.9906 20.8656 2.9906 20.1781V17.0844C2.9906 13.7156 5.7406 10.9656 9.10935 10.9656H12.925C16.2937 10.9656 19.0437 13.7156 19.0437 17.0844V20.1781C19.0094 20.8312 18.4594 21.4156 17.7719 21.4156ZM4.53748 19.8687H17.4969V17.0844C17.4969 14.575 15.4344 12.5125 12.925 12.5125H9.07498C6.5656 12.5125 4.5031 14.575 4.5031 17.0844V19.8687H4.53748Z"
                                                    fill=""
                                                />
                                            </svg>
                                            My Profile
                                        </Link>
                                    </li>
                                </ul>
                                <button className="flex items-center gap-3.5 py-4 px-6 text-sm  duration-300 ease-in-out hover:text-primary lg:text-base" onClick={onLogout}>
                                    <svg
                                        className="fill-current"
                                        width="22"
                                        height="22"
                                        viewBox="0 0 22 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15.5375 0.618744H11.6531C10.7594 0.618744 10.0031 1.37499 10.0031 2.26874V4.64062C10.0031 5.05312 10.3469 5.39687 10.7594 5.39687C11.1719 5.39687 11.55 5.05312 11.55 4.64062V2.23437C11.55 2.16562 11.5844 2.13124 11.6531 2.13124H15.5375C16.3625 2.13124 17.0156 2.78437 17.0156 3.60937V18.3562C17.0156 19.1812 16.3625 19.8344 15.5375 19.8344H11.6531C11.5844 19.8344 11.55 19.8 11.55 19.7312V17.3594C11.55 16.9469 11.2062 16.6031 10.7594 16.6031C10.3125 16.6031 10.0031 16.9469 10.0031 17.3594V19.7312C10.0031 20.625 10.7594 21.3812 11.6531 21.3812H15.5375C17.2219 21.3812 18.5625 20.0062 18.5625 18.3562V3.64374C18.5625 1.95937 17.1875 0.618744 15.5375 0.618744Z"
                                            fill=""
                                        />
                                        <path
                                            d="M6.05001 11.7563H12.2031C12.6156 11.7563 12.9594 11.4125 12.9594 11C12.9594 10.5875 12.6156 10.2438 12.2031 10.2438H6.08439L8.21564 8.07813C8.52501 7.76875 8.52501 7.2875 8.21564 6.97812C7.90626 6.66875 7.42501 6.66875 7.11564 6.97812L3.67814 10.4844C3.36876 10.7938 3.36876 11.275 3.67814 11.5844L7.11564 15.0906C7.25314 15.2281 7.45939 15.3312 7.66564 15.3312C7.87189 15.3312 8.04376 15.2625 8.21564 15.125C8.52501 14.8156 8.52501 14.3344 8.21564 14.025L6.05001 11.7563Z"
                                            fill=""
                                        />
                                    </svg>
                                    Log Out
                                </button>
                            </div>
                        }
                    />

                </div>

                {/* <DropdownUser /> */}

            </div>
        </header>
    );
};

export default Header;
