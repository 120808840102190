import React, { useState } from 'react'
import {
    getAuth, sendPasswordResetEmail
} from "firebase/auth";
import { app } from '../config/firebaseConfig';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';


function ForgotPassword() {
    const [email, setEmail] = useState(undefined)
    const auth = getAuth(app);
    const navigate = useNavigate()

    async function onFormSubmit() {
        try {
            const response = await sendPasswordResetEmail(auth, email)
            // console.log("response>>", response)
            message.success("Reset link sent successfully !!")
            navigate("/login")
        } catch (error) {
            console.log("reset password err>>", error)

        }
    }

    return (
        <div className="rounded-sm border border-stroke bg-brand_bg shadow-default lg:py-20 lg:px-64 ">
            <div className='lg:rounded-3xl lg:bg-white'>
                <div className='text-center bg-phone_login_page_image pt-20 pb-10 rounded-b-3xl text-white lg:rounded-t-3xl  lg:bg-web_login_page_image bg-cover lg:px-30'>
                    <h1 className='text-3xl font-bold mb-2'>Validtech.AI</h1>
                    <p className='text-2xl font-bold'>Reset Password</p>
                    {/* <p className='text-lg px-10'>Login with your email and password
                        or use google sign in option.</p> */}
                </div>

                <div className="flex justify-center items-center lg:px-30" >
                    <div className="w-full p-4 sm:p-12.5 xl:p-17.5">
                        <div className="mb-4">
                            <label className="mb-2.5 block font-medium text-black dark:text-white">
                                Email address
                            </label>
                            <div className="relative">
                                <input
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    type="text"
                                    placeholder="Enter email address"
                                    className="w-full rounded-lg border border-stroke bg-white py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                                />
                                <span className="absolute right-4 top-4">
                                    <svg
                                        className="fill-current"
                                        width="22"
                                        height="22"
                                        viewBox="0 0 22 22"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g opacity="0.5">
                                            <path
                                                d="M19.2516 3.30005H2.75156C1.58281 3.30005 0.585938 4.26255 0.585938 5.46567V16.6032C0.585938 17.7719 1.54844 18.7688 2.75156 18.7688H19.2516C20.4203 18.7688 21.4172 17.8063 21.4172 16.6032V5.4313C21.4172 4.26255 20.4203 3.30005 19.2516 3.30005ZM19.2516 4.84692C19.2859 4.84692 19.3203 4.84692 19.3547 4.84692L11.0016 10.2094L2.64844 4.84692C2.68281 4.84692 2.71719 4.84692 2.75156 4.84692H19.2516ZM19.2516 17.1532H2.75156C2.40781 17.1532 2.13281 16.8782 2.13281 16.5344V6.35942L10.1766 11.5157C10.4172 11.6875 10.6922 11.7563 10.9672 11.7563C11.2422 11.7563 11.5172 11.6875 11.7578 11.5157L19.8016 6.35942V16.5688C19.8703 16.9125 19.5953 17.1532 19.2516 17.1532Z"
                                                fill=""
                                            />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div className="mb-5">
                            <button
                                onClick={() => onFormSubmit()}
                                className="w-full cursor-pointer rounded-lg border border-primary bg-brand_btn p-4 text-white transition hover:bg-opacity-90"
                            >Send Reset Password Link</button>
                        </div>

                    </div>
                </div>
            </div>
            {/* <h1 className='py-2 text-xl'>forgot passwword</h1> */}
        </div>
    )
}

export default ForgotPassword
