import React, { Suspense, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

// components
// import SideBar from "./components/SideBar";
import Loader from "./components/Loader";

import Header from "./components/Header";
import Sidebar from './components/Sidebar';

// css
import "./css/common.css";
import "./css/form.css";

export default function AppContent() {
    const { pathname } = useLocation()
    const [sidebarOpen, setSidebarOpen] = useState(false);

    // useEffect(() => {
    //     console.log("location>>>", location)
    // }, [location])
    return (
        <>
            {/* <Flex justify="start" align="start">
                <div
                    style={{
                        width: collapse ? "82px" : "256px",
                        height: "100vh",
                        position: "sticky",
                        top: 0,
                        background: "var(--body)",
                        borderRight: "1px solid var(--disableFont)",
                    }}
                >
                    <SideBar toggleSidebar={toggleMenu} collapse={collapse} />
                </div>

                <Flex vertical style={{ width: "100%" }}>
                    <div
                        style={{
                            position: "sticky",
                            top: 0,
                            width: "100%",
                            height: "60px",
                            zIndex: 1000,
                            borderBottom: "1px solid var(--disableFont)",
                            background: "var(--body)",
                        }}
                    >
                        <Header toggleSidebar={toggleMenu} collapse={collapse} />
                    </div>
                    <div className="container">
                        <h1>outlet</h1>
                        <Suspense fallback={<Loader />}>
                            <Outlet />
                        </Suspense>
                    </div>
                </Flex>
            </Flex> */}
            <div className="dark:bg-boxdark-2 dark:text-bodydark">
                {/* <!-- ===== Page Wrapper Start ===== --> */}
                <div className="flex h-screen overflow-hidden">
                    {/* <!-- ===== Sidebar Start ===== --> */}
                    <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    {/* <!-- ===== Sidebar End ===== --> */}

                    {/* <!-- ===== Content Area Start ===== --> */}
                    {/* <div className={`relative flex flex-1 flex-col overflow-y-auto  overflow-x-hidden ${pathname === "/" ? "bg-phone_home_bg_image lg:bg-none bg-brand_bg" : "bg-brand_bg"} `}> */}
                    <div className={`relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden ${pathname === "/" ? "bg-phone_home_bg_image bg-cover lg:bg-none bg-brand_bg" : "bg-brand_bg"} `}>
                        {/* <!-- ===== Header Start ===== --> */}
                        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} homeScreen={pathname === "/"} />
                        {/* <!-- ===== Header End ===== --> */}
                        {/* <!-- ===== Main Content Start ===== --> */}
                        <main>
                            <Suspense fallback={<Loader />}>
                                <Outlet />
                            </Suspense>
                        </main>
                        {/* <!-- ===== Main Content End ===== --> */}
                    </div>
                    {/* <!-- ===== Content Area End ===== --> */}
                </div>
                {/* <!-- ===== Page Wrapper End ===== --> */}
            </div>
        </>
    );
}
