import React, { useState } from 'react'

function DropDownMenu({ buttonContent, dropDownContent }) {
    const [show, setShow] = useState(false)
    const [overlayShow, setOverlayShow] = useState(false)

    function onDropDownClick() {
        setTimeout(() => {
            setShow(false)
            setOverlayShow(false)
        }, 100);
    }
    return (
        <div>
            <button className='cursor-pointer' onClick={() => { setShow(!show); setOverlayShow(!overlayShow) }}>{buttonContent}</button>
            {show &&
                <div className='absolute top-10 z-[50] bg-white divide-gray-100 rounded-lg shadow dark:bg-gray-700' onClick={onDropDownClick}>
                    {dropDownContent}
                </div>
            }
            {
                overlayShow &&
                <div className='fixed top-0 left-0 w-full h-full bg-[#00000000] z-[40]'
                    onClick={() => { setShow(false); setOverlayShow(false) }}></div>
            }

        </div>
    )
}

export default DropDownMenu
