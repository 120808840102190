import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Endpoints from '../config/apiConstants'
import callApi from '../helpers/networkHelper'
import PartyTwoDataFillForm from '../components/PartyTwoDataFillForm'

function PartyTwoDataFillPage() {
    const location = useLocation()
    const [token, setToken] = useState(undefined)
    const [verifyToke, setVerifyToke] = useState(false)
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState(undefined)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        if (location.search) {
            console.log("location>>>", location.search.split("?")[1].split("=")[1])
            setToken(location.search.split("?")[1].split("=")[1])
            verifyTokenFun(location.search.split("?")[1].split("=")[1])
        }
    }, [])

    async function verifyTokenFun(tokenData) {
        const method = Endpoints.VERIFY_PARTY_TWO_TOKEN.method;
        const url = Endpoints.VERIFY_PARTY_TWO_TOKEN.url;
        const body = {
            token: tokenData,
        }
        setLoading(true)

        try {
            const response = await callApi(method, url, body);
            console.log("verify token res>>", response)

            if (response.data.status) {
                setVerifyToke(true)
                setFormData(response.data.data)
            } else {
                setVerifyToke(false)
            }
        } catch (err) {
            console.error("token verify fun err", err);
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="flex flex-col justify-center items-center  lg:px-28 px-0 py-0">
            {
                loading && (
                    <h1 className='py-20 text-title-lg lg:text-title-xl text-center' >Wait verifying token</h1>
                )
            }
            {!success && !loading && !verifyToke && (
                <h1 className='py-20 text-title-lg lg:text-title-xl text-center'>This page is no longer available !!!</h1>
            )}
            {
                !success && verifyToke && (
                    <>
                        {/* <h1 className='my-2'>Fill your data</h1> */}
                        <div className='w-full px-4'>
                            <PartyTwoDataFillForm formData={formData} setSuccess={setSuccess} />
                        </div>
                    </>
                )
            }
            {
                success && verifyToke && (
                    <h1 className='py-20 text-title-lg lg:text-title-xl text-center' >Data updated successfully</h1>
                )
            }
        </div>
    )
}

export default PartyTwoDataFillPage