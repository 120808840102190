import App from "../App";
import modules from "./routesModules";
import Login from "../screens/Login";
import Signup from "../screens/Signup";
import NotFound from "../components/NotFound";
import PartyTwoDataFillPage from "../screens/PartyTwoDataFillPage";
import ForgotPassword from "../screens/ForgotPassword";
const routes = [
    { path: "/login", element: <Login /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/signup", element: <Signup /> },
    { path: "/partyTwoDataFill", element: <PartyTwoDataFillPage /> },
    {
        path: "/",
        element: <App />,
        errorElement: <NotFound />,
        children: [...modules],
    },
];

export default routes;
