import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import routes from "../../routes/routesModules";

// components
import NotAuthorized from "../NotAuthorized";
import NotFound from "../NotFound";

const isAuthenticated = (WrappedComponent) =>
    function (props) {
        // get user data from redux store
        const loginData = useSelector((state) => state?.login?.data);
        // console.log("loginData", loginData);
        //get route path
        const location = useLocation();
        //generate path name from url
        let split = location.pathname.split("/");
        let pathName = "";
        if (split.length > 3) {
            split.pop();
            split.shift();
            pathName = split.join("/");
        } else {
            pathName = split[1];
        }

        // check module based on path
        let module = routes.find((x) => x.name === pathName);
        // console.log("path name>>", pathName);
        // console.log("module>>", module);
        if (!module) return <NotFound />;

        // check if user is not logged in redirect to login page
        if (!loginData?.satus) return <Navigate to="/login" replace />;

        //check user has access for path
        const userType = loginData.roleName;
        // const userType = "general";
        // if user hasn't access redirect to unauthorized
        if (!module.permission.includes(userType)) {
            return <NotAuthorized />;
        }

        // if everything oky return component
        return <WrappedComponent {...props} />;
    };

export default isAuthenticated;
