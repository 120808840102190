import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from '../assets/logo/logo-icon.svg';
import { IoMdArrowBack } from "react-icons/io";
// import SidebarLinkGroup from './SidebarLinkGroup';
import { useDispatch, useSelector } from "react-redux";
import { logOutUser } from "../reducers/login";
import { LiaFileContractSolid } from "react-icons/lia";
import { IoMdHome } from "react-icons/io";
import { FaQuestionCircle } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa";
import { IoChatbubbles } from "react-icons/io5";


const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { pathname } = location;

    const trigger = useRef(null);
    const sidebar = useRef(null);

    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    const [sidebarExpanded, setSidebarExpanded] = useState(
        storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
    );

    function onLogout() {
        dispatch(logOutUser());
    }

    // close on click outside
    useEffect(() => {
        const clickHandler = (target) => {
            if (
                !sidebarOpen ||
                sidebar.current?.contains(target) ||
                trigger.current?.contains(target)
            )
                return;
            if (
                !sidebarOpen
            )
                return;
            setSidebarOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    }, []);

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = (keyCode) => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    }, []);

    useEffect(() => {
        localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
        if (sidebarExpanded) {
            document.querySelector('body')?.classList.add('sidebar-expanded');
        } else {
            document.querySelector('body')?.classList.remove('sidebar-expanded');
        }
    }, [sidebarExpanded]);

    return (
        <aside
            ref={sidebar}
            className={`lg:hidden absolute left-0 top-0 z-[50] flex h-screen w-72.5 flex-col overflow-y-hidden bg-white duration-300 ease-linear lg:static lg:translate-x-0 ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'
                }`}
        >
            <div className='h-full flex flex-col justify-between'>
                <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
                    {/* <!-- Sidebar Menu --> */}
                    <nav className="mt-5 py-4 px-4 lg:mt-9 lg:px-6">
                        {/* <!-- Menu Group --> */}
                        <div>
                            <div className='flex justify-between'>
                                <h3 className="mb-4 ml-4 text-lg font-semibold text-bodydark2">
                                    Validtech.Ai
                                </h3>
                                <IoMdClose onClick={() => setSidebarOpen(false)} className='text-lg text-[#adadc4]' />
                            </div>


                            <ul className="mb-6 flex flex-col gap-1.5">

                                <li>
                                    <NavLink
                                        to="/"
                                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-black duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('calendar') &&
                                            'bg-graydark dark:bg-meta-4'
                                            }`}
                                        onClick={() => setSidebarOpen(!sidebarOpen)}
                                    >
                                        <IoMdHome className='text-2xl text-blue-600' />
                                        Home
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/profile"
                                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-black duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('calendar') &&
                                            'bg-graydark dark:bg-meta-4'
                                            }`}
                                        onClick={() => setSidebarOpen(!sidebarOpen)}
                                    >
                                        <FaUserAlt className='text-xl text-blue-600' />
                                        Accounts
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink
                                        to="/billing"
                                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-black duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('calendar') &&
                                            'bg-graydark dark:bg-meta-4'
                                            }`}
                                        onClick={() => setSidebarOpen(!sidebarOpen)}
                                    >
                                        <LiaFileContractSolid className='text-2xl text-blue-600' />
                                        Billing Details
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/policy"
                                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-black duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('calendar') &&
                                            'bg-graydark dark:bg-meta-4'
                                            }`}
                                        onClick={() => setSidebarOpen(!sidebarOpen)}
                                    >
                                        <FaQuestionCircle className='text-xl text-blue-600' />
                                        Privacy Policy FAQ
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/faq"
                                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-black duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('calendar') &&
                                            'bg-graydark dark:bg-meta-4'
                                            }`}
                                        onClick={() => setSidebarOpen(!sidebarOpen)}
                                    >
                                        <IoChatbubbles className='text-xl text-blue-600' />
                                        FAQ
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div className='border-t border-dashed border-black mx-6'>
                    <div className='flex justify-start items-center'>
                        <div className=' bg-slate-500 pr-1'>
                            <FaArrowRight className='text-lg text-white' />
                        </div>
                        <span className='ml-2' onClick={onLogout}>Log out</span>
                    </div>
                    <div className='text-xs text-slate-300 mt-8 mb-4'>
                        <p>App version 1.0.0</p>
                        <p>2024 All right reserved, Validtech.AI</p>
                    </div>
                </div>
            </div>
        </aside>
    );
};

export default Sidebar;
