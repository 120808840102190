import { createSlice } from "@reduxjs/toolkit";

import CookieHelper from "../helpers/cookieHelper";

const cookieHelper = new CookieHelper();
const getUserFromSession = () => {
    const data = cookieHelper.getCookie();
    return data;
};

const initialState = {
    data: getUserFromSession(),
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setLoginData(state, action) {
            // console.log("login reducer received payload", action.payload);
            state.data = { loggedIn: true, ...action.payload };
        },

        logOutUser(state, action) {
            localStorage.clear();
            cookieHelper.clearCookie();
            state.data = { loggedIn: false, userData: {} };
        },
    },
});

export const { setLoginData, logOutUser } = loginSlice.actions;
export default loginSlice.reducer;
